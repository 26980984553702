// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-alden-case-study-js": () => import("./../../../src/pages/projects/aldenCaseStudy.js" /* webpackChunkName: "component---src-pages-projects-alden-case-study-js" */),
  "component---src-pages-projects-botbuilder-case-study-js": () => import("./../../../src/pages/projects/botbuilderCaseStudy.js" /* webpackChunkName: "component---src-pages-projects-botbuilder-case-study-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-private-integrations-private-js": () => import("./../../../src/pages/projects/private/integrationsPrivate.js" /* webpackChunkName: "component---src-pages-projects-private-integrations-private-js" */),
  "component---src-pages-projects-private-private-project-a-js": () => import("./../../../src/pages/projects/private/privateProjectA.js" /* webpackChunkName: "component---src-pages-projects-private-private-project-a-js" */),
  "component---src-pages-projects-project-a-js": () => import("./../../../src/pages/projects/projectA.js" /* webpackChunkName: "component---src-pages-projects-project-a-js" */),
  "component---src-pages-projects-ql-case-study-js": () => import("./../../../src/pages/projects/qlCaseStudy.js" /* webpackChunkName: "component---src-pages-projects-ql-case-study-js" */)
}

